import { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import Persona from "../components/Persona";
import CustomError from "../utils/CustomError";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PersonaSelection: FC = () => {
    const navigate = useNavigate();
    const personaList = [
        {
            id: "1",
            personaId: "john",
            name: "John",
            about: "Hi! I'm John, everyone's favorite product consultant",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona1.png'
        },
        {
            id: "2",
            personaId: "sarah",
            name: "Sarah",
            about: "I'm Sarah, a passionate software engineer who loves coding.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona2.png'
        },
        {
            id: "3",
            personaId: "mike",
            name: "Mike",
            about: "Hello, I'm Mike! I work as a data scientist, analyzing big data for insights.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona3.png'
        },
        {
            id: "4",
            personaId: "emma",
            name: "Emma",
            about: "I'm Emma, a UX/UI designer focused on creating user-friendly experiences.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona4.png'
        },
        {
            id: "5",
            personaId: "david",
            name: "David",
            about: "Hey, I'm David. I'm a digital marketing strategist helping businesses grow online.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona5.png'
        },
        {
            id: "6",
            personaId: "linda",
            name: "Linda",
            about: "Hi, I'm Linda. I'm a full-stack developer working with cutting-edge technologies.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona1.png'
        },
        {
            id: "7",
            personaId: "alex",
            name: "Alex",
            about: "I'm Alex! A project manager who ensures that everything runs smoothly.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona2.png'
        },
        {
            id: "8",
            personaId: "olivia",
            name: "Olivia",
            about: "Hey, I'm Olivia! I focus on product management, ensuring the best solutions for our users.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona3.png'
        },
        {
            id: "9",
            personaId: "ethan",
            name: "Ethan",
            about: "I'm Ethan, a passionate AI enthusiast working on machine learning algorithms.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona4.png'
        },
        {
            id: "10",
            personaId: "chloe",
            name: "Chloe",
            about: "Hi! I'm Chloe, a frontend developer who loves building interactive web applications.",
            imgSrc: `${process.env.PUBLIC_URL}` + '/assets/img/persona5.png'
        },
    ];


    const [activeIndex, setActiveIndex] = useState<number>(3);

    const [oldSlide, setOldSlide] = useState(personaList.length - 1);
    const [activeSlide, setActiveSlide] = useState(3);
    const [countActive, setCountActive] = useState(1);

    const handleItemClick = (index: number) => {
        setActiveIndex(index);
    };
    useEffect(() => {
        setActiveSlide(3);
        setOldSlide(personaList.length - 1)
    }, []);


    var settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        centerMode: true,
        dots: false,
        nextArrow: <img
            src={`${process.env.PUBLIC_URL}/assets/img/right-arrow.svg`}
            alt="right-arrow"
        />,
        prevArrow: <img
            src={`${process.env.PUBLIC_URL}/assets/img/left-arrow.svg`}
            alt="left-arrow"
        />,
        className: "center",
        centerPadding: "0px",
        beforeChange: (current: any, next: any) => {
            const totalSlides = personaList.length;
            let activeSlide = (next + 3) % totalSlides;
            if (activeSlide === 0 && next + 3 !== 0) {
                activeSlide = totalSlides;
            }
            let oldSlide = next - 1;
            if (oldSlide === 0) {
                oldSlide = totalSlides;
            }
            if (oldSlide === -1) {
                oldSlide = 9;
            }
            setActiveSlide(activeSlide);
            setOldSlide(oldSlide);
            setCountActive(next + 1);
            console.log('Next:', next, 'oldSlide', oldSlide)

        },
        responsive:
            [

                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerPadding: "80px",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        centerPadding: "60px",
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
    };

    return (
        <>
            <section className="persona-section">
                <Container>
                    <Row className="justify-content-center">
                        <Col xxl={12} xl={11} md={12} sm={11} xs={12}>
                            <Slider {...settings}>
                                {personaList.map((item: any, index: number) => (
                                    <div
                                        key={index}
                                        className={`${activeSlide === index + 1 ? 'lastActive' : ""} ${oldSlide === index + 1 ? 'firstActive' : ""}`}
                                    >
                                        <div
                                            className="item-inside"
                                        >
                                            <div
                                                className={`person-list`}
                                                onClick={() =>
                                                    handleItemClick(item?.id)
                                                }
                                            >
                                                <Persona
                                                    about={item?.about}
                                                    imgSrc={item?.imgSrc}
                                                    name={item?.name}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <Row className="justify-content-center">
                                <Col xs="auto">
                                    <div className="count-middle text-center mt-2">
                                        {countActive} / {personaList.length}
                                    </div>
                                </Col>
                            </Row>
                            <div className="text-center mt-lg-5 mt-2">
                                <Button variant="" onClick={() => navigate('/chat-screen')} className="start-btn">Start</Button>
                            </div>
                            <CustomError class="mt-lg-5 mt-4" message="Select a persona amongst the list and then click on Start Chat to start the conversation!" />
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}
export default PersonaSelection;