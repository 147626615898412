import { FC, useEffect, useState } from "react";
import Logo from "./Logo";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import HowToUseModal from "./modals/HowToUseModal";
import Heading from "./Heading";

const Header: FC = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const location = useLocation();

    useEffect(() => {
        console.log(location.pathname);
        if (location.pathname !== '/chat-screen') {
            document.body?.classList.remove('hide-title');
        }
        else {
            document.body?.classList.add('hide-title');
        }
    }, [location.pathname]);

    const handleClick = () => {
        if (location.pathname === '/chat-screen') {
            const isConfirmed = window.confirm('The chat history will be lost. Are you sure you want to continue?');
            if (isConfirmed) {
                navigate('/chat-mode');
            } else {
                console.log('User canceled the action');
            }
        }
        else {
            navigate('/chat-mode');
        }
    };

    return (
        <>
            <HowToUseModal setShow={show} onClose={() => setShow(false)} />
            <header>
                <Container>
                    <Row className="justify-content-between">
                        <Col id="no-print" md={{ order: 1, span: 2 }} xs={{ order: 1, span: 6 }}>
                            <Logo />
                        </Col>
                        {location.pathname !== "/chat-screen" ?
                            ""
                            :
                            <Col md={{ order: 2, span: 8 }} xs={{ order: 3, span: 12 }}>
                                <Heading />
                            </Col>
                        }
                        <Col id="no-print" md={{ order: 3, span: 2 }} xs={{ order: 2, span: 6 }} className="text-end">
                            <Stack direction="horizontal" gap={3} className="justify-content-end">
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="" className="p-0" id="dropdown-basic">
                                        <div className="hamburger" id="hamburger-1">
                                            <span className="line"></span>
                                            <span className="line"></span>
                                            <span className="line"></span>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={handleClick}>Home</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={() => {
                                            setShow(true);
                                        }
                                        }>Instructions</Dropdown.Item>
                                        <Dropdown.Item href="#">Feedback</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="" className="p-0" id="dropdown-basic">
                                        <div className="profile-icon">
                                            <Image src={process.env.PUBLIC_URL + '/assets/img/user.svg'} />
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#" onClick={() => navigate("/")}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Stack>
                        </Col>
                        {location.pathname === "/chat-screen" ?
                            ""
                            : <Col className="mt-4 mb-3" xs={{ order: 3, span: 12 }}>
                                <Heading />
                            </Col>}
                    </Row>
                </Container>
            </header >
        </>
    )
}
export default Header;