import { FC } from "react";


const Heading: FC = () => {

    return (
        <>
            <div className="main-content text-center">
                <h1>UQ Virtual <span>Audiology</span> Lab</h1>
                <p className="m-0">Mastering Patient Encounters</p>
            </div>
        </>
    );

}
export default Heading;